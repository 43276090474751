import { createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "row g-6 mb-6 g-xl-9 mb-xl-9" }
const _hoisted_3 = { class: "text-reader" }
const _hoisted_4 = { class: "col-md-5" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = {
  key: 0,
  class: "col-md-10"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("label", null, "Please a select variant:", -1 /* HOISTED */)),
      _withDirectives(_createElementVNode("select", {
        name: "status",
        "data-control": "select2",
        "data-hide-search": "true",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.variant) = $event)),
        class: "form-select form-select-white form-select-sm m-l-12 w-300px"
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("option", {
          value: "null",
          disabled: "",
          selected: "",
          hidden: ""
        }, "Variant *", -1 /* HOISTED */),
        _createElementVNode("option", { value: "sigfox" }, "Sigfox", -1 /* HOISTED */),
        _createElementVNode("option", { value: "lora" }, "Lora", -1 /* HOISTED */),
        _createElementVNode("option", { value: "nbiot" }, "NB IoT", -1 /* HOISTED */)
      ]), 512 /* NEED_PATCH */), [
        [_vModelSelect, _ctx.variant]
      ])
    ]),
    _createCommentVNode("begin::Row"),
    _createElementVNode("div", _hoisted_2, [
      _createCommentVNode("begin::Followers"),
      _createElementVNode("label", _hoisted_3, [
        _createElementVNode("input", {
          type: "file",
          class: "btn btn-primary",
          accept: ".csv",
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleFileUpload($event)))
        }, null, 32 /* NEED_HYDRATION */)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("button", {
          type: "button",
          style: {"float":"right","margin-left":"3px"},
          class: "btn btn-primary"
        }, " Preview ", -1 /* HOISTED */)),
        _createElementVNode("button", {
          style: {"float":"right"},
          type: "submit",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
          class: "btn btn-primary"
        }, " Upload "),
        _cache[6] || (_cache[6] = _createElementVNode("a", {
          target: "_blank",
          download: "",
          href: "/sample_files/Lora.csv"
        }, " Sample CSV Lora ", -1 /* HOISTED */)),
        _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
        _cache[8] || (_cache[8] = _createElementVNode("a", {
          target: "_blank",
          download: "",
          href: "/sample_files/Sigfox.csv"
        }, " Sample CSV Sigfox ", -1 /* HOISTED */)),
        _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
        _cache[10] || (_cache[10] = _createElementVNode("a", {
          target: "_blank",
          download: "",
          href: "/sample_files/NBIoTEm-Com.csv"
        }, " Sample CSV NBIOT ", -1 /* HOISTED */))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "col-md-2" }, null, -1 /* HOISTED */)),
        (_ctx.respCSV.success)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("b", null, "Added IDs (" + _toDisplayString(_ctx.respCSV.added.length) + "):", 1 /* TEXT */),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.respCSV.added, (device, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(device.deviceId), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */)),
              _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
              _createElementVNode("b", null, "Skipped IDs (" + _toDisplayString(_ctx.respCSV.skipped.length) + "):", 1 /* TEXT */),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.respCSV.skipped, (device, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(device.deviceId) + ", ", 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */)),
              _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
              _createElementVNode("b", null, "Error messages (" + _toDisplayString(_ctx.respCSV.errors.length) + "):", 1 /* TEXT */),
              _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.respCSV.errors, (device, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, [
                  _createTextVNode(" Device Id " + _toDisplayString(device.deviceId) + ": ", 1 /* TEXT */),
                  (_ctx.isObject(device.message))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(device.message, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(item[0]), 1 /* TEXT */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]))
                    : _createCommentVNode("v-if", true),
                  (!_ctx.isObject(device.message))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(device.message), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                  _createCommentVNode(" </span> ")
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createCommentVNode("end::Followers")
    ]),
    _createCommentVNode("end::Row")
  ], 64 /* STABLE_FRAGMENT */))
}